<template>
  <b-form @submit.prevent="onSubmit" class="pt-3">
    <b-form-group
      :invalid-feedback="
        getErrorMessage(errors, 'mobileOtp') ||
          getErrorMessage(errors, 'message')
      "
      :state="
        getErrorState(errors, 'mobileOtp') || getErrorState(errors, 'message')
      "
    >
      <b-form-input
        :state="
          getErrorState(errors, 'mobileOtp') || getErrorState(errors, 'message')
        "
        type="number"
        id="mobileOtp"
        v-model="form.mobileOtp"
        placeholder="Mobile OTP"
        trim
      />
    </b-form-group>

    <b-button
      type="submit"
      variant="primary"
      class="text-uppercase text-white mt-5"
      pill
      block
    >
      <span> <b-icon-arrow-right scale="0.7" /> Submit </span>
    </b-button>
  </b-form>
</template>

<script>
import { verifyOtp } from "../../apis";

export default {
  props: {
    otpData: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      form: {
        mobileOtp: "",
      },
      errors: null,
    };
  },
  methods: {
    onSubmit() {
      this.errors = null;
      const dataToSend = {
        ...this.otpData,
        ...this.form,
      };

      verifyOtp(dataToSend)
        .then((data) => {
          this.$emit("success", data);
        })
        .catch(({ data }) => {
          this.errors = data.errors;
        });
    },
  },
};
</script>

<style></style>
