<template>
  <div>
    <!-- Title -->
    <b-card-title class="text-primary mb-3">Verify OTP</b-card-title>

    <!-- Alert -->
    <b-alert variant="info" dismissible show fade>
      An OTP has been sent to your mobile number. Please enter the OTP.
    </b-alert>

    <verify-otp :otpData="otpData" @success="otpSuccess" />
  </div>
</template>

<script>
import { mapActions } from "vuex";
import VerifyOtp from "../../components/auth/VerifyOtp.vue";
export default {
  components: { VerifyOtp },
  created() {
    if (!this.otpData) {
      // no otp data found, return to login
      this.$router.replace({ name: "Home" });
      return;
    }
  },
  data() {
    return {
      query: this.$route.query,
      params: this.$route.params,
    };
  },
  computed: {
    otpData() {
      return this.params.otpData;
    },
  },
  methods: {
    ...mapActions({
      saveToken: "login/saveToken",
    }),
    otpSuccess(data) {
      //save token
      const token = data.token;
      if (token) {
        this.saveToken(token).then(() => {
          let redirect = this.query.redirect;
          if (!redirect) redirect = "Home";
          this.$router.replace({
            name: redirect,
            params: { ...this.params, ...this.query },
            query: this.query,
          });
          return;
        });
      }
    },
  },
};
</script>

<style></style>
